<!-- eslint-disable no-undef -->
<template>
  <div :hidden="!isBusSelected" class="bus-location">
    <v-row justify="center">
      <v-col md="9">
        <v-card class="mx-3 my-2" elevation="0">
          <h4>
            {{selectedBus.name}}
          </h4>
          <h6>
            Last Updated: {{ this.moment(selectedBus.last_location.last_updated).fromNow() }}
          </h6>
          <h6>
            Last Updated by: {{ selectedBus.last_location.updated_by?selectedBus.last_location.updated_by.full_name : ""}}
          </h6>
        </v-card>

        <v-card class="mx-3 my-5">
          <section ref="map-section" id="map-section"></section>
        </v-card>

        <v-row v-if="currentUser.is_driver" justify="center">
           <h3 v-if="!isGeolocationSupported" ref="err-msg" class="red--text">
            Your device does not support Location.
          </h3>
          <h3 v-else-if="!isGeolocationEnabled" ref="err-msg" class="red--text">
            Please enable Location to continue.
          </h3>
        </v-row>

        <v-row justify="center" class="mx-3">
            <v-col v-if="currentUser.is_driver" md="4" cols="6">
              <v-btn
                medium
                block
                :disabled="isGeolocationEnabled ? isTrackingOn : true"
                color="green"
                outlined
                @click="startTrackingDriverLocation()"
              >
                Start Location
              </v-btn>
            </v-col>
            <v-col v-if="currentUser.is_driver" md="4" cols="6">
              <v-btn
                medium
                block
                color="red"
                :disabled="!isTrackingOn"
                outlined
                @click="stopTrackingDriverLocation()"
              >
                Stop Location
              </v-btn>
            </v-col>
            <v-col md="4" cols="6">
              <v-btn
                medium
                block
                color="blue"
                :disabled="!isTrackingOn"
                outlined
                @click="map.setCenter(busLocation)"
              >
                Recentre Map
              </v-btn>
            </v-col>
        </v-row>
      </v-col>
    </v-row>

    <choose-bus-dialog
      :visible="!isBusSelected"
      @busSelected="
        (bus) => {
          isBusSelected = true;
          selectedBus = bus;
          this.startTrackingSelectedBusLocation();
        }
      "
    />
  </div>
</template>

<script>
import { Geolocation } from "@capacitor/geolocation"
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";
import ChooseBusDialog from "@components/dialogs/ChooseBusDialog.vue";
import moment from 'moment';

export default {
  components: { ChooseBusDialog },
  name: "BusLocation",
  mixins: [Mixins.essentials],
  data() {
    return {
      // General
      map: null,
      moment: moment,
      numDeltas: 100,
      delayInMilliSeconds: 10,
      isBusSelected: false,
      selectedBus: {
        name: null,
        last_location:{
          last_updated: null,
          updated_by:{
            full_name: ""
          }
        }
      },
      prevLocation: { lat: null, lng: null },
      busLocation: null,
      busMarker: null,
      isTrackingOn: false,
      busTrackingInterval: null,

      // For driver
      geoLocator: null,
      isGeolocationSupported: false,
      isGeolocationEnabled: false,
      driverLocationInterval: null,
    };
  },

  computed: {
    ...mapGetters(["currentUser", "currentInstitution", "accessToken"]),

    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
  },

  methods: {
    ...mapActions(["showSnackbar", "setLoading"]),

    async checkGeoLocationPermission() {
      await Geolocation.checkPermissions().then(
        (response) => {
          this.isGeolocationSupported = true;
          switch(response.location){
            case 'granted':
              Geolocation.getCurrentPosition().then(
                () => { this.isGeolocationEnabled = true }
              ).catch(
                () => {
                  this.isGeolocationEnabled = false;
                  this.showSnackbar({
                    text: "To continue, Please enable Location.",
                    type: "info",
                  });
                }
              );
              break;

            default:
              // Prompt by using getCurrentPosition
              Geolocation.getCurrentPosition().then(
                () => { this.$router.go() }
              ).catch(
                () => {
                  this.isGeolocationEnabled = false;
                  this.showSnackbar({
                    text: "Location access denied. To continue, Please allow access to location.",
                    type: "info",
                  });
                }
              );
              break;
          }
        }).catch(
          (error) => {
            this.isGeolocationSupported = false;
            this.isGeolocationEnabled = false;
            this.showSnackbar({
              text: "Your device does not support Location.",
              type: "error",
            });
            console.log(error);
          }
        );
    },

    async sendLocationToServer(newLocation, apiMethod) {
      var objToSend= {};
      var url = this.endpoints.busLocations;
      if(apiMethod == 'POST')
        objToSend = {
          bus: this.selectedBus.id,
          lat: parseFloat(newLocation.lat.toFixed(6)) || null,
          lng: parseFloat(newLocation.lng.toFixed(6)) || null,
          updated_by: this.currentUser.id,
        };
      else{
        url += this.prevLocation.id+'/';
      }

      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods[apiMethod],
        objToSend
      );
      return response;
    },

    async initMarkerPosition(location) {
      this.busLocation = location;
      this.busMarker = new google.maps.Marker({
        position: location,
        map: this.map,
        icon: {
          url: require('../../assets/images/sideface-bus.png'),
          scaledSize: new google.maps.Size(60,60),
        },
        optimized: false,
        animation: google.maps.Animation.DROP,
      });
      // this.busMarker.circle = new google.maps.Circle({
      //   map: this.map,
      //   radius: 30,
      //   strokeColor: "#ffffff",
      //   fillColor: "#75daff",
      // });
      // this.busMarker.circle.bindTo("center", this.busMarker,"position");
      this.busMarker.setMap(this.map);
      this.map.setCenter(location);
      this.map.setZoom(18);
    },

    async updateMarkerPosition(newLocation) {
      // https://www.codexworld.com/google-map-move-marker-smoothly-javascript-api/
      var deltaLat = (newLocation.lat - this.busLocation.lat) / this.numDeltas;
      var deltaLng = (newLocation.lng - this.busLocation.lng) / this.numDeltas;
      if (deltaLat==0 && deltaLng==0) return;
      var i = 0;
      while (i++ != this.numDeltas) {
        this.busLocation.lat += deltaLat;
        this.busLocation.lng += deltaLng;
        this.busMarker.setPosition(this.busLocation);
        this.map.setCenter(this.busLocation);
        await new Promise((resolve) =>
          setTimeout(resolve, this.delayInMilliSeconds)
        );
      }
    },

    async successCallbackDriver(position) {
      var newLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      if (newLocation.lat != this.prevLocation?.lat || newLocation.lng != this.prevLocation?.lng) {
        this.prevLocation = await this.sendLocationToServer(newLocation, 'POST');
        this.prevLocation.lat = parseFloat(this.prevLocation.lat) || null;
        this.prevLocation.lng = parseFloat(this.prevLocation.lng) || null;
        if (this.busMarker == null) this.initMarkerPosition(newLocation);
        else this.updateMarkerPosition(newLocation);
      } 
      else {
        this.prevLocation = await this.sendLocationToServer(newLocation, 'PATCH');
      }
      this.selectedBus.last_location = this.prevLocation;
    },

    errorCallbackDriver(error) {
      this.showSnackbar({
        text: `Unable to update position. Retrying in a moment. Error: ${error.message}`,
        type: "error",
      });
    },

    trackDriverLocation(){
      this.geoLocator = Geolocation.getCurrentPosition({ enableHighAccuracy: true}).then(
        (position) => this.successCallbackDriver(position)
      ).catch(
        (err) => this.errorCallbackDriver(err)
      );
    },

    startTrackingDriverLocation() {
      const self = this;
      this.trackDriverLocation();
      this.driverLocationInterval = setInterval(async () => {
        self.trackDriverLocation();
      }, 7000);
      this.showSnackbar({ text: "Tracking your location...", type: "success" });
      this.isTrackingOn = true;
    },

    stopTrackingDriverLocation() {
      if (this.driverLocationInterval) clearInterval(this.driverLocationInterval);
      this.isTrackingOn = false;
      this.busMarker.setMap(null); 
      /* this.busMarker.circle.setMap(null); */ 
      this.busMarker = null;
      this.prevLocation = {lat: null, lng: null};
      this.showSnackbar({
        text: "Location tracking stopped!",
        type: "info",
      });
    },

    async markLocationOnMap(newLocation){
      if (newLocation.lat == this.prevLocation.lat && newLocation.lng == this.prevLocation.lng) return;
      if (this.busMarker == null) this.initMarkerPosition(newLocation);
      else this.updateMarkerPosition(newLocation);
      this.prevLocation = newLocation;
    },

    async trackSelectedBusLocation(){
      if (this.selectedBus.id){
        this.selectedBus.last_location = (await this.api.call(
          this.essentials, 
          this.Helper.addUrlParams(this.endpoints.allBuses,"id="+this.selectedBus.id),
          this.api.Methods.GET
        ))[0].last_location;
        try{
          var newLocation = {
            lat: parseFloat(this.selectedBus.last_location.lat),
            lng: parseFloat(this.selectedBus.last_location.lng),
          }
          if (isNaN(newLocation.lat) || isNaN(newLocation.lng)) throw "Last Location of Bus Not Found";
          this.markLocationOnMap(newLocation);
        }
        catch(e){
          this.showSnackbar({
            text: "Last Location of Bus Not Found",
            type: "info",
          });
        }
      }
    },

    startTrackingSelectedBusLocation(){
      if (this.currentUser.is_driver) return;
      this.isTrackingOn=true;
      this.trackSelectedBusLocation();
      const self = this;
      this.busTrackingInterval = setInterval(async () => {
        // This will be executed every 10 seconds
        self.trackSelectedBusLocation();
      }, 10000);
    },
  },

  async created() {
    if (this.currentUser.is_driver) {
      this.checkGeoLocationPermission();
    }
  },

  mounted() {
    /*global google*/
    /*eslint no-undef: ["error", { "typeof": true }] */
    this.map = new google.maps.Map(this.$refs["map-section"], {
      zoom: 5,
      center: { lat:22, lng: 79},
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      rotateControl: true,
      gestureHandling: 'cooperative',
    });
    var overlay = new google.maps.OverlayView();
    overlay.draw=function(){
      this.getPanes().markerLayer.id='markerLayer';
    };
    overlay.setMap(this.map);
  },

  beforeDestroy() {
    if (this.driverLocationInterval) clearInterval(this.driverLocationInterval);
    if (this.busTrackingInterval) clearInterval(this.busTrackingInterval);
  },
};
</script>

<style>
#map-section {
  /* margin-top: 2%; */
  width: 100%;
  height: 75vh;
  background-color: #d3d3d3;
}

/*marker animations */
#markerLayer img {
  animation: pulse 0.7s ease-in infinite alternate;
  transform-origin: center;
}

/* Animations */
@keyframes pulse{
  to { 
    transform: scale(0.85);
  }
}

@-moz-keyframes pulse{
  to { 
    transform: scale(0.85);
    -moz-transform: scale(0.85);  
  }
}

@-webkit-keyframes pulse{
  to { 
    transform: scale(0.85);
    -webkit-transform: scale(0.85);  
  }
}
</style>