<template>
  <div class="choose-bus-dialog">
    <v-dialog
      :value="visible"
      max-width="500"
      persistent
      @click:outside="$router.back()"
    >
      <v-card>
        <v-card-title>
          Select Bus <v-spacer></v-spacer>
          <v-btn icon @click="$router.back()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <v-select
                v-model="selectedBus"
                :items="buses"
                return-object
                item-text="name"
                hide-details="auto"
              >
              </v-select>
            </v-col>
            <v-col class="mt-2" cols="12">
              <v-btn
                @click="emitBus(selectedBus)"
                color="primary"
                block
                :disabled="!selectedBus"
                >Select</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";
export default {
  name: "ChooseBusDialog",
  mixins: [Mixins.essentials],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      buses: [],
      selectedBus: null,
    };
  },

  computed: {
    ...mapGetters(["currentUser","allBuses"]),
  },

  methods: {
    ...mapActions(["showSnackbar"]),

    async emitBus(bus) {
      this.$emit("busSelected", bus);
      window.scroll(0, 0);
    },

    async getValidBuses(){
      if (this.currentUser.is_admin) this.buses = this.allBuses;
      else if (this.currentUser.is_driver)
        return this.buses = this.allBuses.filter(bus => bus.drivers.includes(this.currentUser.id))
    }
  },

  async created() {
    if (this.currentUser.is_student){ 
      var busId = this.currentUser.bus;
      var url = this.Helper.addUrlParams(this.endpoints.allBuses,"id="+busId,)
      var response = await this.api.call(this.essentials, url, this.api.Methods.GET)
      this.emitBus(response[0]);
    }
    else
      await this.getValidBuses();
  },
};
</script>